import { useAuth0 } from '@auth0/auth0-react';
import { Info } from 'lucide-react';
import { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import Settings from '../../Settings';
import { toast } from 'react-toastify';

const SettingsOrganization: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [apiKeyTeamTailor, setApiKeyTeamTailor] = useState<string>('');

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${Settings.API_URL}/organizations/integrations`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.ok) {
          const integrations: any[] = await response.json();
          
          const teamTailorIntegration = integrations.find(
            integration => integration.platform === 'teamtailor'
          );

          if (teamTailorIntegration?.data?.apiKey) {
            setApiKeyTeamTailor(teamTailorIntegration.data.apiKey);
          }
        } else {
          const errorData = await response.json();
          console.error('Failed to fetch integrations:', errorData);
        }
      } catch (error) {
        console.error('Error fetching integrations:', error);
      }
    };

    fetchIntegrations();
  }, [getAccessTokenSilently]);

  const saveSettings = async () => {
    const token = await getAccessTokenSilently();
    const settings = {
      data: { apiKey: apiKeyTeamTailor },
      name: 'teamtailor'
    }
    const updatedSettings = await fetch(
      `${Settings.API_URL}/organizations/integrations`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(settings)
      }
    );

    if(updatedSettings.ok) {
      toast("Settings updated");
    } else {
      const updatedSettingsResult = await updatedSettings.json();
      toast(updatedSettingsResult.error);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="grid lg:grid-cols-2 gap-4 sm:gap-6">
        <div className="px-6 py-4 min-h-[410px] flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
          <div className="mb-4 sm:mb-8">
            <label
              htmlFor="hs-feedback-post-comment-name-1"
              className="block mb-2 text-sm font-medium text-gray-500 dark:text-neutral-400"
            >
              Teamtailor API key
              <span className="cursor-pointer text-sm max-w-40 tooltip-container-badge inline-block text-gray-800 dark:text-neutral-200">
                <Tooltip
                  className="max-w-[200px]"
                  id="tooltip-teamtailor-integration"
                  style={{ zIndex: 99 }}
                  place="right"
                />
                <Info
                  className="ml-1 w-4 h-4 tooltip text-gray-400"
                  data-tooltip-id="tooltip-teamtailor-integration"
                  data-tooltip-content="Enter your TeamTailor API key here"
                />
              </span>
            </label>
            <p className="text-sm text-gray-600 dark:text-neutral-200 mb-1">
              Enter your TeamTailor API key to indicate that candidates have done the Worklens screening in TeamTailor.
            </p>
            <input
              type="text"
              id="teamtailor-api-key"
              className="bg-gray-100 py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 text-black"
              placeholder="API key"
              value={apiKeyTeamTailor}
              onChange={(e) => setApiKeyTeamTailor(e.target.value)}
            />
          </div>
          <div className="mt-2 flex items-end">
            <button
              onClick={saveSettings}
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            >
              Save settings
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsOrganization;