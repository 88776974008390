import { t } from 'i18next';
import { Bot } from 'lucide-react';
import { useState, useEffect } from 'react';

interface Message {
  id: number;
  text: string;
  sender: 'bot' | 'user';
  visible: boolean;
  img?: string;
}

interface IndustryScreening {
  title: string;
  initialMessages: Message[];
}

const ChatDemo: React.FC = () => {
  const screeningScenarios: Record<string, IndustryScreening> = {
    Healthcare: {
      title: t('chat_demo_healthcare_title'),
      initialMessages: [
        { id: 1, text: t('chat_demo_healthcare_q1'), sender: 'bot', visible: true },
        { id: 2, text: t('chat_demo_healthcare_q2'), sender: 'user', visible: false, img: "https://images.unsplash.com/photo-1541101767792-f9b2b1c4f127?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&&auto=format&fit=facearea&facepad=3&w=300&h=300&q=80" },
        { id: 3, text: t('chat_demo_healthcare_q3'), sender: 'bot', visible: false },
        { id: 4, text: t('chat_demo_healthcare_q4'), sender: 'user', visible: false, img: "https://images.unsplash.com/photo-1541101767792-f9b2b1c4f127?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&&auto=format&fit=facearea&facepad=3&w=300&h=300&q=80" },
        { id: 5, text: t('chat_demo_healthcare_q5'), sender: 'bot', visible: false },
        { id: 6, text: t('chat_demo_healthcare_q6'), sender: 'user', visible: false, img: "https://images.unsplash.com/photo-1541101767792-f9b2b1c4f127?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&&auto=format&fit=facearea&facepad=3&w=300&h=300&q=80" }
      ]
    },
    Retail: {
      title: t('chat_demo_retail_title'),
      initialMessages: [
        { id: 1, text: t('chat_demo_retail_q1'), sender: 'bot', visible: true },
        { id: 2, text: t('chat_demo_retail_q2'), sender: 'user', visible: false, img: "https://plus.unsplash.com/premium_photo-1690407617542-2f210cf20d7e?q=80&w=2417&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" },
        { id: 3, text: t('chat_demo_retail_q3'), sender: 'bot', visible: false },
        { id: 4, text: t('chat_demo_retail_q4'), sender: 'user', visible: false, img: "https://plus.unsplash.com/premium_photo-1690407617542-2f210cf20d7e?q=80&w=2417&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" },
        { id: 5, text: t('chat_demo_retail_q5'), sender: 'bot', visible: false },
        { id: 6, text: t('chat_demo_retail_q6'), sender: 'user', visible: false, img: "https://plus.unsplash.com/premium_photo-1690407617542-2f210cf20d7e?q=80&w=2417&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" }
      ]
    },
    'Food service': {
      title: t('chat_demo_food_service_title'),
      initialMessages: [
        { id: 1, text: t('chat_demo_food_service_q1'), sender: 'bot', visible: true },
        { id: 2, text: t('chat_demo_food_service_q2'), sender: 'user', visible: false, img: "https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" },
        { id: 3, text: t('chat_demo_food_service_q3'), sender: 'bot', visible: false },
        { id: 4, text: t('chat_demo_food_service_q4'), sender: 'user', visible: false, img: "https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" },
        { id: 5, text: t('chat_demo_food_service_q5'), sender: 'bot', visible: false },
        { id: 6, text: t('chat_demo_food_service_q6'), sender: 'user', visible: false, img: "https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" }
      ]
    },
    IT: {
      title: t('chat_demo_it_title'),
      initialMessages: [
        { id: 1, text: t('chat_demo_it_q1'), sender: 'bot', visible: true },
        { id: 2, text: t('chat_demo_it_q2'), sender: 'user', visible: false, img: "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" },
        { id: 3, text: t('chat_demo_it_q3'), sender: 'bot', visible: false },
        { id: 4, text: t('chat_demo_it_q4'), sender: 'user', visible: false, img: "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" },
        { id: 5, text: t('chat_demo_it_q5'), sender: 'bot', visible: false },
        { id: 6, text: t('chat_demo_it_q6'), sender: 'user', visible: false, img: "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" }
      ]
    },
  };

  const [currentIndustry, setCurrentIndustry] = useState<string>('IT');
  const [messages, setMessages] = useState<Message[]>(screeningScenarios['IT'].initialMessages);

  // Tracks the currently active typing process
  let currentTyping: { cancel: boolean } = { cancel: false };

  const showMessage = (id: number, text?: string) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === id ? { ...msg, visible: true, text: text || msg.text } : msg
      )
    );
  };

  const typeMessage = async (id: number, fullText: string) => {
    currentTyping.cancel = false; // Reset cancel flag for a new typing sequence
    for (let i = 1; i <= fullText.length; i++) {
      if (currentTyping.cancel) return; // Stop if a new industry is selected
      showMessage(id, fullText.slice(0, i));
      await new Promise((resolve) => setTimeout(resolve, 50));
    }
  };

  const handleIndustryChange = (industry: string) => {
    setCurrentIndustry(industry);

    // Cancel current animations
    currentTyping.cancel = true;

    // Reset messages to initial state for the new industry
    const newMessages = screeningScenarios[industry].initialMessages.map((msg) => ({
      ...msg,
      visible: msg.id === 1, // Only show the first message initially
    }));
    setMessages(newMessages);
  };

  useEffect(() => {
    let isCancelled = false;

    const processMessages = async () => {
      const currentMessages = screeningScenarios[currentIndustry].initialMessages;
      for (let i = 0; i < currentMessages.length; i++) {
        if (isCancelled) break;

        const message = currentMessages[i];
        if (message.sender === 'bot') {
          showMessage(message.id);
        } else {
          await typeMessage(message.id, message.text);
        }

        // Delay before next message
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    };

    // Cancel any ongoing animations and reset messages
    currentTyping.cancel = true;
    setMessages((prevMessages) =>
      prevMessages.map((msg) => ({
        ...msg,
        visible: msg.id === 1, // Only show the first message
      }))
    );

    processMessages();

    return () => {
      isCancelled = true;
      currentTyping.cancel = true;
    };
  }, [currentIndustry]);

  return (
    <div className="text-center mx-auto lg:max-w-lg lg:mx-auto lg:me-0 ms-auto mt-8 lg:mt-0">
      <div className="inline-flex rounded-lg shadow-sm mb-4">
        {Object.entries(screeningScenarios).map(([industryKey, industry]) => (
          <button
            key={industryKey}
            type="button"
            onClick={() => handleIndustryChange(industryKey)}
            className={`py-2 px-3 inline-flex justify-center items-center gap-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 shadow-sm outline-none disabled:opacity-50 disabled:pointer-events-none ${currentIndustry === industryKey
              ? 'bg-blue-600 text-white dark:border-neutral-700'
              : 'bg-white text-gray-800 dark:bg-neutral-900 dark:border-neutral-700 dark:text-white'
              }`}
          >
            {industry.title}
          </button>
        ))}
      </div>

      <div className="p-4 sm:p-7 flex flex-col bg-white rounded-2xl shadow-lg dark:bg-neutral-900">
        <ul className="space-y-5">
          {messages.map(
            (message) =>
              message.visible && (
                <li
                  key={message.id}
                  className={`flex items-start ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                >
                  {message.sender === 'bot' && (
                    <Bot className="mr-3 w-8 h-8 text-black dark:text-white" />
                  )}
                  <div
                    className={`${message.sender === 'user'
                      ? 'bg-blue-600 text-white text-right'
                      : 'bg-white border border-gray-200 text-gray-800 text-left dark:bg-neutral-900 dark:text-white'
                      } rounded-2xl p-4 max-w-md`}
                  >
                    <p className="text-sm">{message.text}</p>
                  </div>
                  {message.sender === 'user' && (
                    <img
                      className="inline-block size-9 rounded-full ml-3"
                      src={message.img}
                      alt="User Avatar"
                    />
                  )}
                </li>
              )
          )}
        </ul>
      </div>
    </div>
  );
};

export default ChatDemo;