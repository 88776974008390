import { useEffect } from 'react';

const ToggleLightDarkMode: React.FC = () => {
  const toggleTheme = (theme: 'light' | 'dark') => {
    const html = document.querySelector('html');
    localStorage.setItem('hs_theme', theme);
    
    if (theme === 'light') {
      html?.classList.remove('dark');
      html?.classList.add('light');
    } else {
      html?.classList.remove('light');
      html?.classList.add('dark');
    }
  };

  useEffect(() => {
    const html = document.querySelector('html');
    const isLightOrAuto =
      localStorage.getItem('hs_theme') === 'light' ||
      (localStorage.getItem('hs_theme') === 'auto' &&
        !window.matchMedia('(prefers-color-scheme: dark)').matches);
    const isDarkOrAuto =
      localStorage.getItem('hs_theme') === 'dark' ||
      (localStorage.getItem('hs_theme') === 'auto' &&
        window.matchMedia('(prefers-color-scheme: dark)').matches);

    if (isLightOrAuto && html?.classList.contains('dark')) {
      html.classList.remove('dark');
    } else if (isDarkOrAuto && !html?.classList.contains('dark')) {
      html?.classList.add('dark');
    }
  }, []);

  return (
    <>
      <button
        type="button"
        className="hs-dark-mode-active:hidden block hs-dark-mode font-medium text-gray-800 rounded-full focus:outline-none dark:text-neutral-200"
        onClick={() => toggleTheme('dark')}
        aria-label="Switch to dark mode"
      >
        <span className="group inline-flex shrink-0 justify-center items-center size-9">
          <svg
            className="shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z"></path>
          </svg>
        </span>
      </button>
      <button
        type="button"
        className="hs-dark-mode-active:block hidden hs-dark-mode font-medium text-gray-800 rounded-full focus:outline-none dark:text-neutral-200"
        onClick={() => toggleTheme('light')}
        aria-label="Switch to light mode"
      >
        <span className="group inline-flex shrink-0 justify-center items-center size-9">
          <svg
            className="shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="4"></circle>
            <path d="M12 2v2"></path>
            <path d="M12 20v2"></path>
            <path d="m4.93 4.93 1.41 1.41"></path>
            <path d="m17.66 17.66 1.41 1.41"></path>
            <path d="M2 12h2"></path>
            <path d="M20 12h2"></path>
            <path d="m6.34 17.66-1.41 1.41"></path>
            <path d="m19.07 4.93-1.41 1.41"></path>
          </svg>
        </span>
      </button>
    </>
  );
};

export default ToggleLightDarkMode;
