import { useAuth0 } from '@auth0/auth0-react';
import { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface Candidate {
  email: string;
  email_verified: boolean;
  name: string;
  picture: string;
  locale: { country: string, language: string };
  given_name: string;
  family_name: string;
  isFirstLogin: boolean;
  acceptedTermsAt: string;
}

interface AuthContextType {
  candidate: Candidate | null;
  isAuthenticatedCandidate: boolean;
  loading: boolean;
  loginCandidate: (candidate: Candidate) => void;
  logoutCandidate: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [candidate, setCandidate] = useState<Candidate | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedCandidate = localStorage.getItem('candidate');

    if (storedCandidate) {
      setCandidate(JSON.parse(storedCandidate));
    }
    setLoading(false);
  }, []);

  const loginCandidate = (candidate: Candidate) => {
    setCandidate(candidate);
    localStorage.setItem('candidate', JSON.stringify(candidate));
  };

  const logoutCandidate = () => {
    setCandidate(null);
    localStorage.removeItem('candidate');
    localStorage.removeItem('candidateToken');
  };

  const value = {
    candidate,
    isAuthenticatedCandidate: candidate !== null && !loading,
    loading,
    loginCandidate,
    logoutCandidate,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const ProtectedRouteCandidate: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticatedCandidate, loading } = useAuth();

  if (loading) return <div>Loading...</div>;

  if (!isAuthenticatedCandidate) {
    toast("You have been logged out");
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export const ProtectedRouteUser: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, isLoading } = useAuth0();

  if (isLoading) return <div>Loading...</div>;

  if (!user) {
    toast("You have been logged out");
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export { AuthContext };
