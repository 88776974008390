import { t } from "i18next";
import { Search } from "lucide-react";
import { useState, useEffect, useRef } from "react";

const SearchDemo: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [currentText, setCurrentText] = useState("");
  const [textIndex, setTextIndex] = useState(0);
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const inputValues = [
    t('search_demo_query1'),
    t('search_demo_query2'),
    t('search_demo_query3')
  ];

  // Viewport detection using Intersection Observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsActive(true);
        }
      },
      { threshold: 0.1 }
    );

    if (searchRef.current) {
      observer.observe(searchRef.current);
    }

    return () => {
      if (searchRef.current) {
        observer.unobserve(searchRef.current);
      }
    };
  }, []);

  // Focus input when activated
  useEffect(() => {
    if (isActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isActive]);

  // Typing animation
  useEffect(() => {
    if (!isActive || textIndex >= inputValues.length) return;

    const text = inputValues[textIndex];
    let currentChar = 0;

    const typingInterval = setInterval(() => {
      if (currentChar < text.length) {
        setCurrentText(text.slice(0, currentChar + 1));
        currentChar++;
      }

      if (currentChar >= text.length) {
        clearInterval(typingInterval);

        // Pause before moving to the next text
        setTimeout(() => {
          setCurrentText(""); // Clear text
          setTextIndex((prev) => (prev + 1) % inputValues.length);
        }, 1000);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, [isActive, textIndex]);

  return (
    <div
      ref={searchRef}
      className={`m-auto search-wrapper ${isActive ? "active" : ""}`}
    >
      <div className="input-holder">
        <input
          ref={inputRef}
          type="text"
          className="search-input !border-none text-white"
          placeholder="Type to search"
          value={currentText}
          readOnly
        />
        <button className="search-icon">
          <Search className="ml-2 w-6 h-6 text-black" />
        </button>
      </div>
    </div>
  );
};

export default SearchDemo;