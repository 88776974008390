import { useState, useEffect, useRef } from 'react';
import Settings from '../../Settings';
import { upperCased } from '../../util';
import { InterviewSettingsLLM, InterviewSettingsLLMTonality, InterviewStateType, OrganizationInterviewCreate, OrganizationInterviewGet, OrganizationInterviewUpdate, QuestionAnswer } from '../../types';
import { useAuth0 } from '@auth0/auth0-react';
import { ArrowLeft, Bot, Info, Send } from 'lucide-react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FormSteps } from './CreateInterview';
import * as React from 'react';

const PreviewInterview: React.FC<{
  interview: OrganizationInterviewGet | OrganizationInterviewCreate;
  setFormStep: React.Dispatch<React.SetStateAction<FormSteps>>;
}> = ({ interview, setFormStep }) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const userAnswerRef = useRef<HTMLTextAreaElement>(null);

  function isUpdateInterview(interview: any): interview is OrganizationInterviewGet {
    return 'id' in interview;
  }

  function isCreateInterview(interview: any): interview is OrganizationInterviewCreate {
    return !('id' in interview);
  }

  const [isSendingAnswer, setIsSendingAnswer] = useState(false);
  const [isCreatingInterviewAsPublished, setIsCreatingInterviewAsPublished] = useState<boolean>(false);
  const [isCreatingInterviewAsDraft, setIsCreatingInterviewAsDraft] = useState<boolean>(false);
  const [isUpdatingInterviewAsPublished, setIsUpdatingInterviewAsPublished] = useState<boolean>(false);
  const [isUpdatingInterviewAsDraft, setIsUpdatingInterviewAsDraft] = useState<boolean>(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [isFollowUp, setIsFollowUp] = useState(false);
  const [followUpQuestion, setFollowUpQuestion] = useState('');
  const [isInterviewCompleted, setIsInterviewCompleted] = useState(false);

  const [questionsAnswers, setQuestionsAnswers] = useState<QuestionAnswer[]>([]);

  const [settingsLLM, setSettingsLLM] = useState<InterviewSettingsLLM>({
    askFollowUpQuestions: false,
    tonality: 1,
  });

  const handleAnswerSubmit = async () => {
    if (interview && interview.questions && userAnswer.trim() !== '') {
      setIsSendingAnswer(true);

      // The current question ID and text
      const questionId = interview.questions[currentQuestionIndex].id;
      const currentQuestionText = isFollowUp
        ? followUpQuestion
        : interview.questions[currentQuestionIndex].text;

      // If we've already triggered a follow-up, stop requesting more
      const updatedSettingsLLM = {
        ...settingsLLM,
        askFollowUpQuestions: settingsLLM.askFollowUpQuestions && !isFollowUp,
      };

      const answerPayload = {
        questionId,
        question: currentQuestionText,
        answer: userAnswer.trim(),
        settingsLLM: updatedSettingsLLM,
      };

      try {
        const answerResponse = await fetch(`${Settings.API_URL}/interviews`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(answerPayload),
        });

        const answer = await answerResponse.json();

        let parsedEvaluation;
        try {
          parsedEvaluation = JSON.parse(answer.evaluationMessage);
        } catch (error) {
          console.error('Failed to parse evaluationMessage:', error);
          parsedEvaluation = { isAdequate: false, followUpQuestion: '' };
        }

        // ---- Store user answer in the local state ----
        if (isFollowUp) {
          // We’re answering the follow-up; append to the latest QA item
          setQuestionsAnswers((prev) =>
            prev.map((qaItem, idx) => {
              if (idx === prev.length - 1) {
                return {
                  ...qaItem,
                  followUps: [
                    ...qaItem.followUps,
                    {
                      id: qaItem.followUps.length,
                      question: followUpQuestion,
                      answer: userAnswer.trim(),
                    },
                  ],
                };
              }
              return qaItem;
            })
          );
        } else {
          // Main question answer; create a new QA item
          const newQuestionAnswer = {
            questionId,
            answer: userAnswer.trim(),
            followUps: [],
          };
          setQuestionsAnswers((prev) => [...prev, newQuestionAnswer]);
        }

        // Clear user’s text input
        setUserAnswer('');

        // ---- Conversation flow logic ----
        if (parsedEvaluation.isAdequate) {
          // If the new answer is good, move to the next question (or finish)
          setIsFollowUp(false);
          if (currentQuestionIndex < interview.questions.length - 1) {
            setCurrentQuestionIndex((prev) => prev + 1);
          } else {
            setIsInterviewCompleted(true);
          }
        } else {
          // The new answer is inadequate
          if (!isFollowUp && settingsLLM.askFollowUpQuestions) {
            // We haven't used our single follow-up yet → ask the follow-up
            setIsFollowUp(true);
            setFollowUpQuestion(parsedEvaluation.followUpQuestion);
          } else {
            // Either we've already used the follow-up or follow-ups are disabled
            // → jump to the next question (or finish)
            setIsFollowUp(false);
            if (currentQuestionIndex < interview.questions.length - 1) {
              setCurrentQuestionIndex((prev) => prev + 1);
            } else {
              setIsInterviewCompleted(true);
            }
          }
        }
      } catch (error) {
        console.error('Failed to submit the answer:', error);
      } finally {
        setIsSendingAnswer(false);
        userAnswerRef.current?.focus();
      }
    }
  };

  const handleAskFollowUpQuestionsChange = (value: boolean) => {
    setSettingsLLM((prev) => ({
      ...prev,
      askFollowUpQuestions: value,
    }));

    setCurrentQuestionIndex(0);
    setIsFollowUp(false);
    setQuestionsAnswers([]);
    setIsInterviewCompleted(false);
  };

  const handleTonalityChange = (value: InterviewSettingsLLMTonality) => {
    setSettingsLLM((prev) => ({
      ...prev,
      tonality: value,
    }));

    setCurrentQuestionIndex(0);
    setIsFollowUp(false);
    setQuestionsAnswers([]);
    setIsInterviewCompleted(false);
  };


  const createInterview = async (stateType: InterviewStateType.Draft | InterviewStateType.Published) => {
    if (isCreateInterview(interview)) {
      if (stateType === InterviewStateType.Draft) {
        setIsCreatingInterviewAsDraft(true);
      }

      if (stateType === InterviewStateType.Published) {
        setIsCreatingInterviewAsPublished(true);
      }

      try {
        const organizationInterviewPost: OrganizationInterviewCreate = {
          title: interview.title,
          jobAd: interview.jobAd,
          questions: interview.questions.map((question) => ({
            id: question.id,
            text: question.text,
            type: question.type
          })),
          employerName: interview.employerName,
          expireAt: interview.expireAt,
          stateType: stateType,
          settingsLLM: settingsLLM
        };

        const body = JSON.stringify(organizationInterviewPost);

        const token = await getAccessTokenSilently();

        const response = await fetch(`${Settings.API_URL}/organizations/interviews`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: body,
        });

        if (response.ok) {
          if (stateType === InterviewStateType.Draft) {
            toast("Interview saved as draft");
          } else if (stateType === InterviewStateType.Published) {
            toast("Interview published");
          }

          navigate('/organization/dashboard');
        }

        if (!response.ok || !response.body) {
          throw new Error('Network response was not ok');
        }

      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsCreatingInterviewAsDraft(false);
        setIsCreatingInterviewAsPublished(false);
      }
    }
  };

  const updateInterview = async (stateType: InterviewStateType.Draft | InterviewStateType.Published) => {
    if (isUpdateInterview(interview)) {
      if (stateType === InterviewStateType.Draft) {
        setIsUpdatingInterviewAsDraft(true);
      }

      if (stateType === InterviewStateType.Published) {
        setIsUpdatingInterviewAsPublished(true);
      }

      try {
        const questions = interview?.questions
          ? interview.questions.map((question) => ({
            id: question.id,
            text: question.text,
            type: question.type
          }))
          : [];

        const organizationInterviewUpdate: OrganizationInterviewUpdate = {
          id: interview.id,
          title: interview.title,
          jobAd: interview.jobAd,
          questions: questions,
          expireAt: interview.expireAt,
          stateType: stateType,
          settingsLLM: settingsLLM
        };

        const body = JSON.stringify(organizationInterviewUpdate);

        const token = await getAccessTokenSilently();

        const response = await fetch(`${Settings.API_URL}/organizations/interviews`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: body,
        });

        if (response.ok) {
          if (stateType === InterviewStateType.Draft) {
            toast("Interview saved as draft");
          } else if (stateType === InterviewStateType.Published) {
            toast("Interview published");
          }
        }

        if (!response.ok || !response.body) {
          throw new Error('Network response was not ok');
        }

      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsUpdatingInterviewAsDraft(false);
        setIsUpdatingInterviewAsPublished(false);
      }
    }
  };

  useEffect(() => {
    const loadSettingsLLM = () => {
      if (isUpdateInterview(interview)) {
        setSettingsLLM({
          askFollowUpQuestions: interview.settingsLLM.askFollowUpQuestions,
          tonality: interview.settingsLLM.tonality,
        });
      }
    };

    loadSettingsLLM();
  }, [interview]);

  return (
    <>
      {!interview ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="px-6 py-4 min-h-[410px] flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
            <div className="mb-4 sm:mb-8">
              <span className="block mb-2 text-sm font-medium text-gray-500 dark:text-neutral-400 flex">
                Ask clarification questions&nbsp;
                <div className="tooltip-container">
                  <Info className="w-5 h-5 tooltip text-gray-400" />
                  <span className="tooltiptext">
                    Allows the interview agent to ask for clarification when answers need more detail
                  </span>
                </div>
              </span>
              <div className="flex space-x-4">
                <label className="inline-flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={settingsLLM.askFollowUpQuestions}
                    onChange={() => handleAskFollowUpQuestionsChange(!settingsLLM.askFollowUpQuestions)}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <span className="text-sm text-gray-500 dark:text-neutral-400">Enable</span>
                </label>
              </div>
            </div>

            <div className="mb-4 sm:mb-8">
              <span
                className="block mb-2 text-sm font-medium text-gray-500 dark:text-neutral-400 flex">
                Interview tonality&nbsp;
                <div className="tooltip-container">
                  <Info className="w-5 h-5 tooltip text-gray-400" />
                  <span className="tooltiptext">
                    Sets the overall tonality of the interview agent
                  </span>
                </div>
              </span>
              <div className="space-x-2">
                {Object.entries(InterviewSettingsLLMTonality)
                  .filter(([_, value]) => typeof value === 'number')
                  .map(([key, value]) => (
                    <label key={key} className="inline-flex items-center space-x-2">
                      <input
                        type="radio"
                        name="tonality"
                        value={value}
                        checked={settingsLLM.tonality === value}
                        onChange={() => handleTonalityChange(value as InterviewSettingsLLMTonality)}
                        className="form-radio h-5 w-5 text-blue-600"
                      />
                      <span className="text-sm text-gray-500 dark:text-neutral-400">{key}</span>
                    </label>
                  ))}
              </div>
            </div>

            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setFormStep(FormSteps.Questions)}
                type="button"
                className="ml-auto h-12 py-2 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-blue-600 dark:text-white dark:hover:bg-blue-600"
              >
                <ArrowLeft className="w-4 h-4" />
                Questions
              </button>

              {isCreateInterview(interview) && (
                <>
                  <button
                    type="button"
                    className="h-12 py-2 px-4 flex-grow-0 basis-2/4 inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                    onClick={() => createInterview(InterviewStateType.Draft)}
                    disabled={isCreatingInterviewAsDraft || isCreatingInterviewAsPublished}
                  >
                    {!isCreatingInterviewAsDraft && (
                      <>
                        Save as draft
                      </>
                    )}
                    {isCreatingInterviewAsDraft && (
                      <>
                        Saving draft
                        <div className="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"></div>
                      </>
                    )}
                  </button>
                  <button
                    type="button"
                    className="h-12 py-2 px-4 flex-grow-0 basis-2/4 inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                    onClick={() => createInterview(InterviewStateType.Published)}
                    disabled={isCreatingInterviewAsDraft || isCreatingInterviewAsPublished}
                  >
                    {!isCreatingInterviewAsPublished && (
                      <>
                        Publish interview
                      </>
                    )}
                    {isCreatingInterviewAsPublished && (
                      <>
                        Publishing interview
                        <div className="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"></div>
                      </>
                    )}
                  </button>
                </>
              )}

              {isUpdateInterview(interview) && (
                <>
                  <button
                    type="button"
                    className="h-12 py-2 px-4 flex-grow-0 basis-2/4 inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                    onClick={() => updateInterview(InterviewStateType.Draft)}
                    disabled={isUpdatingInterviewAsDraft || isUpdatingInterviewAsPublished}
                  >
                    {!isUpdatingInterviewAsDraft && (
                      <>
                        Save as draft
                      </>
                    )}
                    {isUpdatingInterviewAsDraft && (
                      <>
                        Saving draft
                        <div className="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"></div>
                      </>
                    )}
                  </button>
                  <button
                    type="button"
                    className="h-12 py-2 px-4 flex-grow-0 basis-2/4 inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                    onClick={() => updateInterview(InterviewStateType.Published)}
                    disabled={isUpdatingInterviewAsDraft || isUpdatingInterviewAsPublished}
                  >
                    {!isUpdatingInterviewAsPublished && (
                      <>
                        Publish interview
                      </>
                    )}
                    {isUpdatingInterviewAsPublished && (
                      <>
                        Publishing interview
                        <div className="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"></div>
                      </>
                    )}
                  </button>
                </>
              )}
            </div>


          </div>

          <div className="p-4 md:p-5 min-h-[410px] flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
            <div>
              <h2 className="text-2xl font-bold mb-8 text-gray-800 dark:text-white">
                Interview for {interview.title} at {upperCased(interview.employerName)}
              </h2>
              <div className="max-w-[40rem] mx-auto">
                <div className="container">
                  <ul className="space-y-5">
                    {questionsAnswers.map((qaItem, index) => (
                      <React.Fragment key={index}>
                        {/* Main Question */}
                        <li className="max-w-lg flex gap-x-2 sm:gap-x-4 me-11">
                          <div className="inline-block size-9 rounded-full">
                            <Bot className="h-10 w-10 text-black dark:text-white" />
                          </div>
                          <div className="bg-white border border-gray-200 rounded-2xl p-4 space-y-3 dark:bg-neutral-900 dark:border-neutral-700">
                            <p className="mb-1.5 text-sm text-gray-800 dark:text-white">
                              {interview.questions?.find((q) => q.id === qaItem.questionId)?.text}
                            </p>
                          </div>
                        </li>
                        {/* Main Answer */}
                        <li className="flex ms-auto gap-x-2 sm:gap-x-4">
                          <div className="grow text-end space-y-3">
                            <div className="inline-block bg-blue-600 rounded-2xl p-4 shadow-sm">
                              <p className="mb-1.5 text-sm text-white">{qaItem.answer}</p>
                            </div>
                          </div>
                          <span className="shrink-0 inline-flex items-center justify-center size-[38px] rounded-full bg-gray-600">
                            <span className="text-sm font-medium text-white leading-none">
                              You
                            </span>
                          </span>
                        </li>
                        {/* Follow-Ups */}
                        {qaItem.followUps.map((followUp, fIndex) => (
                          <React.Fragment key={fIndex}>
                            {/* Follow-Up Question */}
                            <li className="max-w-lg flex gap-x-2 sm:gap-x-4 me-11">
                              <div className="inline-block size-9 rounded-full">
                                <Bot className="h-10 w-10 text-black dark:text-white" />
                              </div>
                              <div className="bg-white border border-gray-200 rounded-2xl p-4 space-y-3 dark:bg-neutral-900 dark:border-neutral-700">
                                <p className="mb-1.5 text-sm text-gray-800 dark:text-white">
                                  {followUp.question}
                                </p>
                              </div>
                            </li>
                            {/* Follow-Up Answer */}
                            <li className="flex ms-auto gap-x-2 sm:gap-x-4">
                              <div className="grow text-end space-y-3">
                                <div className="inline-block bg-blue-600 rounded-2xl p-4 shadow-sm">
                                  <p className="mb-1.5 text-sm text-white">{followUp.answer}</p>
                                </div>
                              </div>
                              <span className="shrink-0 inline-flex items-center justify-center size-[38px] rounded-full bg-gray-600">
                                <span className="text-sm font-medium text-white leading-none">
                                  You
                                </span>
                              </span>
                            </li>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}

                    {/* Current Question */}
                    {interview.questions && interview.questions.length > 0 && currentQuestionIndex < interview.questions.length && (
                      <>
                        {isFollowUp ? (
                          <>
                            {/* Follow-Up Question */}
                            <li className="max-w-lg flex gap-x-2 sm:gap-x-4 me-11">
                              <div className="inline-block size-9 rounded-full">
                                <Bot className="h-10 w-10 text-black dark:text-white" />
                              </div>
                              <div className="bg-white border border-gray-200 rounded-2xl p-4 space-y-3 dark:bg-neutral-900 dark:border-neutral-700">
                                <p className="mb-1.5 text-sm text-gray-800 dark:text-white">
                                  {followUpQuestion}
                                </p>
                              </div>
                            </li>
                          </>
                        ) : (
                          <>
                            {/* Main Question */}
                            <li className="max-w-lg flex gap-x-2 sm:gap-x-4 me-11">
                              <div className="inline-block size-9 rounded-full">
                                <Bot className="h-10 w-10 text-black dark:text-white" />
                              </div>
                              <div className="bg-white border border-gray-200 rounded-2xl p-4 space-y-3 dark:bg-neutral-900 dark:border-neutral-700">
                                <p className="mb-1.5 text-sm text-gray-800 dark:text-white">
                                  {interview.questions[currentQuestionIndex].text}
                                </p>
                              </div>
                            </li>
                          </>
                        )}

                        {/* Answer Input */}
                        <li className="relative !mt-10">
                          <textarea
                            ref={userAnswerRef}
                            className="p-4 pb-20 block w-full bg-gray-100 border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-700 text-black dark:text-white dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                            value={userAnswer}
                            onChange={(e) => setUserAnswer(e.target.value)}
                            placeholder="Your answer"
                          />
                          <div className="absolute bottom-0 px-4 mb-2 inset-x-0 rounded-b-lg bg-gray-100 bg-opacity-0 dark:bg-neutral-800 dark:bg-opacity-0">
                            <div className="flex justify-between items-center">
                              <div className="flex items-center"></div>
                              <div className="flex items-center gap-x-1">
                                <button
                                  type="button"
                                  className="inline-flex shrink-0 justify-center items-center size-8 rounded-lg text-white bg-blue-600 hover:bg-blue-500"
                                  onClick={handleAnswerSubmit}
                                >
                                  {isSendingAnswer ? (
                                    <div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full"></div>
                                  ) : (
                                    <Send className="w-4 h-4" />
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                  {isInterviewCompleted && (
                    <>
                      <p className="mt-4 text-gray-600 dark:text-neutral-400 mb-8">
                        Great job! You’ve completed your interview. Click below to submit your responses.
                      </p>
                      <button
                        type="button"
                        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      >
                        Submit my interview
                        <Send className="w-4 h-4" />
                      </button>
                    </>
                  )}
                </div>
              </div>

            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PreviewInterview;
