import { format } from 'date-fns';
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect, useRef } from 'react';
import Settings from '../../Settings';
import { InterviewStateType, OrganizationInterviewGet } from '../../types';
import { Copy, Pencil, WandSparkles } from 'lucide-react';
import { toast } from 'react-toastify';
import { getInterviewStateLabel, upperCased } from '../../util';
import { NavLink } from 'react-router-dom';
import * as React from 'react';

const DashboardOverview: React.FC = () => {

  const [isLoadingInterviews, setIsLoadingInterviews] = useState<boolean>(false);
  const [interviews, setInterviews] = useState<OrganizationInterviewGet[]>([]);
  const [organizationName, setOrganizationName] = useState('');
  const { getAccessTokenSilently } = useAuth0();

  const copyLinkRef = useRef<HTMLAnchorElement>(null);

  const copyToClipboard = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    const link = event.currentTarget.href;
    navigator.clipboard.writeText(link).then(() => {
      toast("Link copied to clipboard");
    })
  };

  // Fetch interviews on component mount
  useEffect(() => {
    const getAllInterviews = async () => {
      setIsLoadingInterviews(true);
      try {
        const token = await getAccessTokenSilently();

        const responseInterviews = await fetch(`${Settings.API_URL}/organizations/interviews`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });

        if (!responseInterviews.ok) {
          throw new Error('Could not get interviews');
        }

        const interviews: OrganizationInterviewGet[] = await responseInterviews.json();
        setInterviews(interviews);
        setOrganizationName(localStorage.getItem('organizationName')!);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoadingInterviews(false);
      }
    };

    getAllInterviews();
  }, [getAccessTokenSilently]);

  return (
    <div className="flex flex-col">
      <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">
        <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
          <div>
            <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
              Your interviews
              {isLoadingInterviews && (
                <div
                  className="ml-2 animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                  role="status"
                  aria-label="loading"
                ></div>
              )}
            </h2>
          </div>
          <div>
            <div className="inline-flex gap-x-2">
              <NavLink
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                to="/organization/dashboard/interviews/create"
              >
                <svg
                  className="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5v14" />
                </svg>
                Create interview
              </NavLink>
            </div>
          </div>
        </div>

        <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
          <thead className="bg-gray-50 dark:bg-neutral-800">
            <tr>
              <th className="w-1/4 px-6 py-3 text-start">
                <span className="text-xs font-semibold tracking-wide text-gray-800 dark:text-neutral-200">
                  Title
                </span>
              </th>
              <th className="w-1/4 px-6 py-3 text-start">
                <span className="text-xs font-semibold tracking-wide text-gray-800 dark:text-neutral-200">
                  Employer
                </span>
              </th>
              <th className="w-1/4 px-6 py-3 text-start">
                <span className="text-xs font-semibold tracking-wide text-gray-800 dark:text-neutral-200">
                  State
                </span>
              </th>
              <th className="w-1/4 px-6 py-3 text-start">
                <span className="text-xs font-semibold tracking-wide text-gray-800 dark:text-neutral-200">
                  Summaries
                </span>
              </th>
              <th className="w-1/4 px-6 py-3 text-start">
                <span className="text-xs font-semibold tracking-wide text-gray-800 dark:text-neutral-200">
                  Expires
                </span>
              </th>
              <th className="w-1/4 px-6 py-3 text-start">
                <span className="text-xs font-semibold tracking-wide text-gray-800 dark:text-neutral-200">
                  Created
                </span>
              </th>
              <th className="w-1/4 px-6 py-3 text-start">
                <span className="text-xs font-semibold tracking-wide text-gray-800 dark:text-neutral-200">
                  Shareable link
                </span>
              </th>
              <th className="w-1/4 px-6 py-3 text-start">
                <span className="text-xs font-semibold tracking-wide text-gray-800 dark:text-neutral-200">

                </span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {organizationName !== undefined && !isLoadingInterviews && interviews.length > 0 && interviews.map((interview) => (
              <tr key={interview.uuid}>
                <td className="w-1/4 px-6 py-3 text-start">
                  <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                    {upperCased(interview.title.replace(/_/g, ' '))}
                  </span>
                </td>
                <td className="w-1/4 px-6 py-3 text-start">
                  <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                    {upperCased(interview.employerName)}
                  </span>
                </td>
                <td className="w-1/4 px-6 py-3 text-start">
                  {interview.state[0].state === InterviewStateType.Published ? (
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                      <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                        {getInterviewStateLabel(interview.state[0].state)}
                      </span>
                    </span>
                  ) : (
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                      <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-yellow-100 text-yellow-800 rounded-full dark:bg-yellow-500/10 dark:text-yellow-500">
                        {getInterviewStateLabel(interview.state[0].state)}
                      </span>
                    </span>
                  )}
                </td>
                <td className="w-1/4 px-6 py-3 text-start">
                  <span className="text-sm text-gray-500 dark:text-neutral-500">
                    <NavLink
                      className="text-sm flex items-center"
                      to={`/organization/dashboard/interviews/${interview.id}/${interview.uuid}/applications`}
                    >
                      <WandSparkles className="w-4 h-4 cursor-pointer mr-1 text-black dark:text-white" />
                      View
                    </NavLink>
                  </span>
                </td>
                <td className="w-1/4 px-6 py-3 text-start">
                  <span className="text-sm text-gray-500 dark:text-neutral-500">
                    {interview.expireAt ? format(interview.expireAt, 'yyyy-MM-dd') : ''}
                  </span>
                </td>
                <td className="w-1/4 px-6 py-3 text-start">
                  <span className="text-sm text-gray-500 dark:text-neutral-500">
                    {format(interview.created, 'yyyy-MM-dd')}
                  </span>
                </td>
                <td className="w-1/4 px-6 py-3 text-start">
                  <span className="text-sm">
                    <a
                      href={`${Settings.WEB_URL}/interview/${organizationName.replace(/ /g, '-')}/${interview.title.replace(/ /g, '-')}/${interview.id}`}
                      ref={copyLinkRef}
                      onClick={copyToClipboard}
                      rel="noopener noreferrer"
                    >
                      <Copy className="w-4 h-4 cursor-pointer" />
                    </a>
                  </span>
                </td>
                <td className="w-1/4 px-6 py-3 text-start">
                  <NavLink
                    className="text-sm flex items-center"
                    to={`/organization/dashboard/interviews/edit/${interview.id}`}>
                    <Pencil className="w-4 h-4 cursor-pointer mr-1 text-black dark:text-white" />
                    Edit
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DashboardOverview;
