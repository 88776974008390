import { useAuth0 } from '@auth0/auth0-react';
import { Blend, CircleUser, Cog, LogOut } from 'lucide-react';
import { NavLink } from 'react-router-dom';
import Settings from '../../Settings';
import ToggleLightDarkMode from '../../components/ToggleLightDarkMode';

const SideNavOrganization: React.FC = () => {
  const { logout } = useAuth0();

  const logoutUser = async () => {
    localStorage.removeItem('organizationName');
    logout({ logoutParams: { returnTo: Settings.WEB_URL } });
  }

  const getClassNames = ({ isActive }: { isActive: boolean }) =>
    `flex items-center gap-x-3.5 py-2 px-2.5 text-sm rounded-lg ${
      isActive
        ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700 dark:text-white'
        : 'text-gray-800 hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-200 dark:hover:text-neutral-300'
    }`;
  

  return (
    <div
      id="hs-application-sidebar"
      className="hs-overlay [--auto-close:lg] hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform w-[260px] h-full hidden fixed inset-y-0 start-0 z-[60] bg-white border-e border-gray-200 lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 dark:bg-neutral-800 dark:border-neutral-700"
      role="dialog"
      aria-label="Sidebar"
    >
      <div className="relative flex flex-col h-full max-h-full">
        <div className="absolute right-0">
          <ToggleLightDarkMode />
        </div>
        <div className="px-6 pt-4 flex items-center">
          <NavLink to="/organization/dashboard" className="no-underline flex items-center">
            <Blend className="w-6 h-6 mr-2" color="#2563eb" />
            <span className="text-[#235bd5] dark:text-white text-xl font-bold">Worklens</span>
          </NavLink>
        </div>
        {/* Content */}
        <div className="h-full overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
          <nav className="hs-accordion-group p-3 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
            <ul className="flex flex-col space-y-1">
              <li>
                <NavLink
                  to="/organization/dashboard"
                  end
                  className={getClassNames}
                >
                  <svg
                    className="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                    <polyline points="9 22 9 12 15 12 15 22" />
                  </svg>
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/organization/dashboard/profile"
                  className={getClassNames}
                >
                  <CircleUser className="w-4 h-4" />
                  My Profile
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/organization/dashboard/settings"
                  className={getClassNames}
                >
                  <Cog className="w-4 h-4" />
                  Settings
                </NavLink>
              </li>
              <li>
                <button
                  onClick={logoutUser}
                  className="w-full text-left flex items-center gap-x-3.5 py-2 px-2.5 text-sm rounded-lg text-gray-800 hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-200 dark:hover:text-neutral-300"
                >
                  <LogOut className="w-4 h-4" />
                  Logout
                </button>
              </li>
            </ul>
          </nav>
        </div>
        {/* End Content */}
      </div>
    </div>
  );
};

export default SideNavOrganization;