import { Blend } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from "../AuthContext";
import { useAuth0 } from '@auth0/auth0-react';
import ToggleLightDarkMode from './ToggleLightDarkMode';
import LanguagePicker from './LanguagePicker';
import { t } from 'i18next';


const Header: React.FC = () => {

  const { loginWithRedirect, user, isAuthenticated: isAuthenticatedUser } = useAuth0();
  const { isAuthenticatedCandidate, candidate } = useAuth();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  const getCurrentLanguage = () => {
    return (localStorage.getItem('language') as 'en' | 'sv') || 'en';
  }

  return (
    <header className="inset-x-0 flex flex-wrap md:justify-start md:flex-nowrap z-50 w-full text-sm">
      <nav className="mt-4 relative max-w-2xl w-full bg-white border border-gray-200 rounded-[2rem] mx-2 py-2.5 md:flex md:items-center md:justify-between md:py-0 md:px-4 md:mx-auto dark:bg-neutral-900 dark:border-neutral-700">
        <div className="px-4 md:px-0 flex justify-between items-center">
          <div className="flex items-center mr-2 -mt-[2px]">
            <Link to="/" className="no-underline flex items-center mr-2">
              <Blend className="w-6 h-6 mr-2" color="#2563eb" />
              <span className="flex-none text-xl font-semibold text-[#235bd5] dark:text-white focus:outline-none focus:opacity-80">Worklens</span>
            </Link>
          </div>
          <div className="hidden md:flex items-center gap-4 ml-auto">
           {/* <a
              className="inline-flex items-center text-sm whitespace-nowrap text-blue-600 hover:text-blue-700 focus:outline-none focus:text-blue-700 dark:text-blue-500 dark:focus:text-blue-400"
              href="#"
            >
              {t('pricing')}
            </a>*/}
            <a
              className="inline-flex items-center text-sm whitespace-nowrap text-blue-600 hover:text-blue-700 focus:outline-none focus:text-blue-700 dark:text-blue-500 dark:focus:text-blue-400"
              href={getCurrentLanguage() === 'en' 
                ? 'https://worklens.ghost.io/tag/worklens-global/' 
                : 'https://worklens.ghost.io/tag/worklens-sverige/'}
              target="_blank"
            >
              {t('blog')}
            </a>
          </div>
          <div className="md:hidden">
            <button
              type="button"
              className="hs-collapse-toggle flex justify-center items-center size-6 border border-gray-200 text-gray-500 rounded-full hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:border-neutral-700 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
              onClick={() => setIsMenuExpanded(!isMenuExpanded)}
            >
              {isMenuExpanded ? (
                <svg
                  className="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              ) : (
                <svg
                  className="shrink-0 size-3.5"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1={3} x2={21} y1={6} y2={6} />
                  <line x1={3} x2={21} y1={12} y2={12} />
                  <line x1={3} x2={21} y1={18} y2={18} />
                </svg>
              )}
            </button>
          </div>
        </div>

        <div
          className={`${isMenuExpanded ? "block" : "hidden"
            } md:block transition-all duration-300 basis-full grow`}
        >
          <div className="flex flex-col md:flex-row md:items-center md:justify-end gap-2 md:gap-3 mt-3 md:mt-0 py-2 md:py-0 md:ps-7">
            {!isAuthenticatedUser && !isAuthenticatedCandidate && (
              <button
                onClick={() => loginWithRedirect()}
                className="py-0.5 md:py-3 px-4 md:px-1 border-transparent text-gray-500 text-gray-800 focus:outline-none dark:text-neutral-400 dark:hover:text-neutral-200 flex items-center space-x-2"
              >
                {t('login')}
              </button>
            )}
            {isAuthenticatedCandidate && candidate && (
              <>
                <Link
                  to="/profile-candidate"
                  className="flex items-center space-x-2 py-0.5 md:py-3 px-4 md:px-1 border-gray-800 font-medium text-gray-800 focus:outline-none dark:border-neutral-200 dark:text-neutral-200"
                >
                  <img
                    className="shrink-0 w-8 h-8 rounded-full"
                    src={candidate.picture || "/default-profile-picture.jpg"}
                    alt="Profile"
                  />
                  <span className="md:hidden text-sm font-medium dark:text-neutral-200 text-gray-800">Profile</span>
                </Link>
              </>
            )}
            {isAuthenticatedUser && user && (
              <Link
                to="/organization/dashboard"
                className="flex items-center space-x-2 py-0.5 md:py-3 px-4 md:px-1 border-gray-800 text-gray-800 focus:outline-none dark:border-neutral-200 dark:text-neutral-200"
              >
                {t('organization')}
              </Link>
            )}
            <Link
              to={getCurrentLanguage() === 'en' 
              ? 'https://worklens.ghost.io/tag/worklens-global/' 
              : 'https://worklens.ghost.io/tag/worklens-sverige/'}
              target="_blank"
              className="md:hidden flex items-center space-x-2 py-0.5 md:py-3 px-4 md:px-1 border-gray-800 text-gray-800 focus:outline-none dark:border-neutral-200 dark:text-neutral-200"
            >
              <span className="text-sm dark:text-neutral-200 text-gray-800">{t('blog')}</span>
            </Link>
            {/*<Link
              to="/organization/dashboard"
              className="md:hidden flex items-center space-x-2 py-0.5 md:py-3 px-4 md:px-1 border-gray-800 font-medium text-gray-800 focus:outline-none dark:border-neutral-200 dark:text-neutral-200"
            >
              <BriefcaseBusiness />
              <span className="text-sm font-medium dark:text-neutral-200 text-gray-800">{t('pricing')}</span>
            </Link>*/}
            <div className="md:hidden pl-4 flex items-center px-2 md:px-1">
              <LanguagePicker showLabel={true} />
            </div>

          </div>
        </div>
      </nav>
      <div className="hidden md:block absolute top-0 right-0">
        <ToggleLightDarkMode />
        <span className="absolute top-[5px] right-[30px]">
          <LanguagePicker showLabel={false} />
        </span>
      </div>
    </header>
  );
};

export default Header;