import { useAuth0 } from '@auth0/auth0-react';


const ProfileUser: React.FC = () => {

  const { user } = useAuth0();

  return (
    <div className="flex flex-col">
      <div className="px-6 py-4 min-h-[410px] flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
            {user?.given_name} {user?.family_name}
          </h2>
        </div>
        <div>
          <img src={user?.picture} alt={user?.name} className="w-20 h-20 rounded-full mb-2" />
          <div className="space-y-3">
            <span className="text-black dark:text-white">
              {user?.email}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileUser;