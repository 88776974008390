import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

const LeadGenForm = () => {
  const [user_email, setUserEmail] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const serviceID = 'service_8363vmn';
    const templateID = 'template_iktayi2';
    const publicKey = 'kM62g9J0XPB2t-VCY';

    emailjs
      .send(
        serviceID,
        templateID,
        { user_email },
        publicKey
      )
      .then(() => {
        setStatus('Thank you! Our team will get back to you shortly.');
        setUserEmail('');
      })
      .catch(() => {
        setStatus('Failed to send. Please try again.');
      });
  };

  return (
    <div className="pb-10 mx-auto">
      <div className="relative rounded-xl p-5 sm:py-16">
        <div className="max-w-xl relative z-10 text-center mx-auto">
          <div className="mb-5">
            <h2 className="text-2xl font-bold md:text-3xl text-gray-800 dark:text-neutral-200">{t('lead_gen_title')}</h2>
            <p className="mt-3 text-gray-500 dark:text-neutral-400">
            <Trans i18nKey="lead_gen_description" components={{ mark: <mark /> }} />
            </p>
          </div>

          {/* EmailJS Form */}
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col sm:flex-row gap-3">
              <input
                type="email"
                name="user_email"
                value={user_email}
                onChange={(e) => setUserEmail(e.target.value)}
                required
                className="p-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent text-gray-800 dark:text-neutral-200"
                placeholder={t('input_email')}
              />
              <button
                type="submit"
                className="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
              >
                {t('lead_gen_book')}
              </button>
            </div>
          </form>

          {/* Status Message */}
          {status && (
            <div className="mt-3 text-gray-800 dark:text-neutral-200">
              {status}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeadGenForm;
