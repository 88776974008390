import { initiateLinkedInAuth } from './util';
import Header from './components/Header';
import Footer from './components/Footer';

const LoginPageCandidate: React.FC = () => {

  const handleLogin = () => {
    initiateLinkedInAuth();
  };

  return (
    <main className="flex-grow">

    <Header />

      <div className="relative overflow-hidden">
        <div className="max-w-[40rem] mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-24">
          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-6 text-center dark:text-white">Login candidate</h2>
            <div className="max-w-sm mx-auto">
              <button
                onClick={handleLogin}
                className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Log In
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      
    </main>
  );
};

export default LoginPageCandidate;